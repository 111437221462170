import React from 'react';
import Animation from './Animation';

export default function HomeGuidance() {
    return (
      <section className="guidance mt-5 ">
        <div className="container">
          <header className="section-header mt-5">
            <h2>Looking for Professional Guidance?</h2>
            <div className="bar"></div>
          </header>
          {/* <!-- Feature Icons --> */}
          <div className="row guidance-icons">
            <div className="row">
              <div className="col-md-12 d-flex content">
                <div className="row">
                  <h3 className="text-center text-dark mt-3 mb-5 ">
                    <span className="sub-head">For Personal</span>
                  </h3>

                  <div className="col-md-12 icon-box text-end ">
                    <div id="parent" className="col-md-9">
                      <h4>Legal Services</h4>
                      <p>
                        We provide simple 26QB compliance for real estate
                        transactions and acquisitions, in addition to all kinds
                        of legal guidance for taxation.
                      </p>
                    </div>
                    <div id="child" className="col-md-3">
                      <img
                        src="/imgs/icons/legal.png"
                        alt="TODAYFILINGS - Legal Services"
                      />
                    </div>
                  </div>

                  <div className="col-md-12 icon-box text-end ">
                    <div id="parent" className="col-md-9">
                      <h4>ITR Filing Assistance</h4>
                      <p>
                        Receive professional help with tax filing for salaried
                        and independent contractors, NRIs, capital gains, and
                        more.
                      </p>
                    </div>
                    <div id="child" className="col-md-3">
                      <img
                        src="/imgs/icons/itr-filing.png"
                        alt="TODAYFILINGS - ITR Filing Assistance"
                      />
                    </div>
                  </div>

                  <div className="col-md-12 icon-box text-end ">
                    <div id="parent" className="col-md-9">
                      <h4>TDS on Sale Property</h4>
                      <p>
                        For the creation and evaluation of legal documents,
                        including sale deeds, vendor agreements, co-founders
                        agreements.
                      </p>
                    </div>
                    <div id="child" className="col-md-3">
                      <img
                        src="/imgs/icons/tds.png"
                        alt="TODAYFILINGS TDS Service"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="guide mt-5 ">
                    <img
                      src="/imgs/values-3.png"
                      alt="TODAYFILINGS"
                      className="guidance_img"
                    />
                  </div>
                </div>
                <div className="row business">
                  <h3
                    className="text-center text-dark mt-3 mb-5 "
                    data-aos-offset="100"
                  >
                    <span className="sub-head">For Business</span>
                  </h3>
                  <div className="col-md-12 icon-box text-left ">
                    <div id="child" className="col-md-3">
                      <img
                        src="/imgs/icons/startup.png"
                        alt="TODAYFILINGS - Launch and Run a Startup service"
                      />
                    </div>
                    <div id="parent" className="col-md-9">
                      <h4>Launch and Run a Startup</h4>
                      <p>
                        Get professional assistance with tax filing for SMEs and
                        legal drafting, documents review, company name change,
                        and more.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-12 icon-box text-left ">
                    <div id="child" className="col-md-3">
                      <img
                        src="/imgs/icons/registration.png"
                        alt="TODAYFILINGS - Government Registration Services "
                      />
                    </div>
                    <div id="parent" className="col-md-9">
                      <h4>Government Registration</h4>
                      <p>
                        Registration for small and medium-sized businesses, shop
                        licences, PF, PAN, FSSAI, and other government
                        regulations
                      </p>
                    </div>
                  </div>

                  <div className="col-md-12 icon-box text-left ">
                    <div id="child" className="col-md-3">
                      <img
                        src="/imgs/icons/trademark.png"
                        alt="TODAYFILINGS -Trademark Registration"
                      />
                    </div>
                    <div id="parent" className="col-md-9">
                      <h4>Trademark Registration</h4>
                      <p>
                        Our KBG digitizes new trademark registrations and
                        renewals of existing trademarks as simple and faster
                        stages.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Feature Icons --> */}
        </div>
        <Animation />
      </section>
    );
}
