import React from "react";
import { Link } from "gatsby";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { TypeAnimation } from 'react-type-animation';
import Animation from "./Animation";

const Hero = ({ sliders }) => {
  return (
    <AwesomeSlider
      bullets={true}
      mobileTouch={true}
      organicArrows={false}
      autoplay={true}
      interval={5000}
    >
      {sliders.map((slider, index) => (
        <section
          className='home_hero'
          key={index}
          style={{ background: slider.background }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-md-6'>
                <div className=' alignment content aos-init aos-animate'>
                  <h1
                    className={`float-start hero-h1 ${
                      slider.header_color ? 'text-white ' : ''
                    }`}
                    // data-aos='fade-down'
                    // data-aos-offset='500'
                  >
                    {slider.header}
                    {/* <TypeAnimation
                      sequence={[slider.header]}
                      speed={50}
                      style={{ fontSize: '1em' }}
                      repeat={Infinity}
                    /> */}
                  </h1>
                  <p
                    className={` hero-p ${
                      slider.content_color ? 'text-white' : ''
                    }`}
                    // data-aos='fade-up'
                    // data-aos-offset='00'
                  >
                    <TypeAnimation
                      sequence={[
                        ...slider.content.map((points, index) => points),
                        1000,
                      ]}
                      speed={10}
                      style={{ fontSize: '2em', textTransform: 'uppercase' }}
                      repeat={Infinity}
                    />
                  </p>
                  <p className='hero-p2'></p>
                  <Link
                    className='btn btn-md btn-success demo-btn'
                    to='https://wa.me/9585055661'
                    style={{ marginLeft: 'auto', marginTop: '0px' }}
                    // data-aos='fade-left'
                    // data-aos-offset='300'
                  >
                    <div
                      className='icons'
                      // data-aos='fade-right'
                      // data-aos-offset='300'
                    >
                      <a
                        href='https://wa.me/9585055661'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-white alig'
                      >
                        Book Now <i className=' bi bi-indent m-right'></i>
                      </a>
                    </div>
                  </Link>
                </div>
              </div>
              <div className='col-md-6'>
                <div
                  className='slide-img float-end'
                  style={{
                    marginTop: slider.marginTop,
                    marginBottom: slider.marginBottom,
                  }}
                >
                  <Animation />
                  <img
                    src={slider.image}
                    className='img-fluid aos-init aos-animate hero-img'
                    alt={slider.alt_tag}
                    data-aos='fade-up'
                    data-aos-offset='200'
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ))}
    </AwesomeSlider>
  );
};

export default Hero;


// {sliders.map((slider, index) => (
//   <section
//     className="home_hero"
//     key={index}
//     style={{ background: slider.background }}
//   >
//     <div className="container">
//       <div className="row">
//         <div className="col-md-6">
//           <div className="content aos-init aos-animate">
//             <h1
//               className={`${
//                 slider.header_color ? "text-white " : "float-start"
//               }`}
//               // data-aos='fade-down'
//               // data-aos-offset='500'
//             >
//               {slider.header}
//               {/* <TypeAnimation
//                 sequence={[slider.header]}
//                 speed={50}
//                 style={{ fontSize: '1em' }}
//                 repeat={Infinity}
//               /> */}
//             </h1>
//             <p
//               className={`${slider.content_color ? "text-white" : ""}`}
//               // data-aos='fade-up'
//               // data-aos-offset='00'
//             >
//               <TypeAnimation
//                 sequence={[
//                   "We produce food for Mice",
//                   1000,
//                   "We produce food for Hamsters",
//                   1000,
//                   slider.content,
//                   2000,
//                 ]}
//                 speed={50}
//                 style={{ fontSize: "1em" }}
//                 repeat={Infinity}
//               />
//             </p>

//             <Link
//               className="btn btn-md btn-success demo-btn"
//               to="https://wa.me/9876543210"
//               // data-aos='fade-left'
//               // data-aos-offset='300'
//             >
//               <div
//                 className="icons"
//                 // data-aos='fade-right'
//                 // data-aos-offset='300'
//               >
//                 <a
//                   href="https://wa.me/9876543210"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="text-white"
//                 >
//                   Book Now <i className="bi bi-indent m-right"></i>
//                 </a>
//               </div>
//             </Link>
//           </div>
//         </div>
//         <div className="col-md-6">
//           <div
//             className="slide-img"
//             style={{
//               marginTop: slider.marginTop,
//               marginBottom: slider.marginBottom,
//             }}
//           >
//             <img
//               src={slider.image}
//               className="img-fluid aos-init aos-animate"
//               alt={slider.alt_tag}
//               data-aos="fade-up"
//               data-aos-offset="200"
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   </section>
// ))}