import React from 'react'

export default function HomeAbout() {
    return (
      <section id="rs-about" className="rs-about mt-5 main-content">
        {/*  <!-- About Section Start --> */}
        <div id="rs-about" className="rs-about style2 mt-3 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 mt-3 ">
                <div className="">
                  <img
                    className="ml-5 mt-5 abt-img img-fluid"
                    src="/imgs/business/sliders/nidhi-slider.png"
                    alt="Best tax and gst filing service in India "
                  />
                </div>
                <img
                  src="/imgs/animation/circle-shape.png"
                  className="bg-image rotateme mt-5"
                  alt="TODAYFILINGS"
                />
              </div>
              <div
                className="col-lg-7 mt-3 mr-5"
                // data-aos='fade-left'
                // data-aos-offset='400'
              >
                <div className="about">
                  <div className="sec-title">
                    <div className="sub-title mb-3 mt-5 primary">
                      About TODAYFILINGS
                    </div>
                    <h2 className="title mb-3">
                      Welcome to the Online Tax Filing and Tax Consultation
                      Service.
                    </h2>
                    <p className="desc">
                      Since our start in 2015, we have established a good
                      reputation as a reliable service provider. Our services
                      focus on us for income tax services, GST consultant
                      services, business startup registration, personal and
                      business GST and ITR filing, and income tax advice.
                    </p>
                    <p className="desc">
                      For the past few years, TODAYFILINGS has been the leading
                      tax counselling and online tax filing service provider in
                      Hosur. For both personal and commercial tax filing, our
                      services are rapid and secure, with end-to-end encryption.
                    </p>
                    <p className="desc">
                      We have the best professional staff and the best approach
                      to treating our clients, as well as the best income tax
                      counselling, legal guidance, and online income tax filing
                      services, which is why we are at the top of the business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}
