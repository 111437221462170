import React from 'react';
import '../styles/index.css';
import Clients from '../components/Clients';
import Testimonial from '../components/Testimonial';
import Layout from '../components/Layout';
import { Seo } from '../components/seo';
// import Service from "../components/Services";
import HomeGuidance from '../components/HomeComponents/HomeGuidance';
// import CardSection from "../components/HomeComponents/CardSection";
// import Tabs from "../components/HomeComponents/Tabs";
// import SecuritySection from "../components/HomeComponents/SecuritySection";
import CounterUp from '../components/HomeComponents/CounterUp';
import HomeAbout from '../components/HomeComponents/rsAbout';
import Card from '../components/HomeComponents/Card';
import HomeSlider from '../components/HomeComponents/Homeslider';
import Document from '../components/Document';
import PricingTabs from '../components/HomeComponents/PricingTabs';
import BlogCard from '../components/BlogCard';

export default function Index() {
  /* Slider */
  const KbgSlider = [
    {
      header_color: false,
      content_color: false,
      background: ``,
      header: `BEST ONLINE TAXATION & STATUTORY REGISTRATIONS SERVICES`,
      content: [
        `GST Registration `,
        `Income Tax Filings`,
        `Tax Planning Optimizer`,
      ],
      image: "/imgs/business/sliders/tax-slider.png",
      alt_tag: "Best Online Private Tax Registration Service in Hosur",
    },
  ];
  /* Service Data */
  // const ServicesData = {
  //   header: `Our Services`,
  //   content: `Built to size, expertly crafted, and designed to be secure.
  //   Providing you with optimum tax savings, unparalleled speed,
  //   and total piece of mind`,
  //   image: '/imgs/e-filing.png',
  //   alt_tag: 'TODAYFILINGS Online Tax Filing',
  //   points: [
  //     `Business Registration`,
  //     `Income Tax E-filing `,
  //     `GST E-Filing`,
  //     `SME GST/ITR E-Filing`,
  //     `Tax Compliances`,
  //     `Legal Advisory Service`,
  //   ],
  // };

  /* Card Component Data */
  const indexCardData = {
    animations: true,
    section_head: false,
    header: "",
    cards: [
      {
        each_card_link: "/gst/gst-registration",
        card_img: "/imgs/icons/bregistration/itr.png",
        card_img_alt: "TODAYFILINGS GST filing service",
        card_head: "GST",
        card_content: `Online GST registration is simple and safe with
        TODAYFILINGS! In India, business operations must register
        GST distinct tax filings.`,
      },
      {
        each_card_link: "/itr-filing",
        card_img_class: "bg-f78acb",
        card_img: "/imgs/icons/itr-filing.png",
        card_img_alt: "TODAYFILINGS ITR E-filing service",
        card_head: "Income Tax Filing",
        card_content: `According to the legislative procedure, ITR E-filing based
        on their income tax payment is required for all business.`,
      },
      {
        each_card_link: "/ipr/trademark-registration",
        card_img_class: "bg-eb6b3d",
        card_img: "/imgs/icons/enterprises.png",
        card_img_alt:
          "TODAYFILINGS - Best Enterprises Products service in Hosur",
        card_head: "Intellectual Property",
        card_content: `We offer to register intellectual property such as artistic creations, designs, and logos, names, and pictures used in your company.`,
      },
      {
        each_card_link: "",
        card_img_class: "bg-c679e3",
        card_img: "/imgs/icons/bregistration/share.png",
        card_img_alt:
          "TODAYFILINGS - Best Enterprises Products service in Hosur",
        card_head: "Tax Consultancy",
        card_content: `You can receive advice from our qualified specialists to
        help with all forms of legal tax consulting services.`,
      },
    ],
  };
  /* Why us Section Data */
  const PvtLtdDocData = {
    id: "why-us",
    heading: `Why TodayFilings?`,
    sub_heading: ``,
    doc_contents: [
      {
        col: `col-lg-3 col-md-6`,
        delay: "200",
        img: `/imgs/home/rating.png`,
        alt_tag: `Five Star Ratings`,
        header: `100+`,
        description: `Five ⭐ Google Review`,
      },
      {
        col: `col-lg-3 col-md-6`,
        delay: "300",
        img: `/imgs/home/consultation.png`,
        alt_tag: `Legal Advices`,
        header: `10000+`,
        description: `Legal advice is offered all over India.`,
      },

      {
        col: `col-lg-3 col-md-6`,
        delay: "600",
        img: `/imgs/home/monitor.png`,
        alt_tag: `Monthly Registration`,
        header: `200+`,
        description: `Every month registration`,
      },
      {
        col: `col-lg-3 col-md-6`,
        delay: "600",
        img: `/imgs/home/team.png`,
        alt_tag: `Team Members`,
        header: `25+`,
        description: `Staffs for your service`,
      },
      {
        col: `col-lg-3 col-md-6`,
        delay: "500",
        img: `/imgs/home/rocket.png`,
        alt_tag: `Experience in field`,
        header: `10+`,
        description: `Years of legal experience focused on TodayFilings`,
      },
      {
        col: `col-lg-3 col-md-6`,
        delay: "600",
        img: `/imgs/home/trophy.png`,
        alt_tag: `Game Changer`,
        header: `Satisfaction`,
        description: `Customer Satisfaction / 100% Money back guarantee`,
      },
      {
        col: `col-lg-3 col-md-6`,
        delay: "700",
        img: `/imgs/home/achievement.png`,
        alt_tag: `Record`,
        header: `Statistics`,
        description: `Highest Company & Trademark Registration`,
      },
      {
        col: `col-lg-3 col-md-6`,
        delay: "800",
        img: `/imgs/home/application.png`,
        alt_tag: `Web Application`,
        header: `Feature`,
        description: `Simple and user friendly web application`,
      },
    ],
  };

  /* Clients */
  const ClientsData = {
    id: "clients",
    header: "Our Clients",
    slides: [
      {
        img: "/imgs/clients/c1.png",
        img_alt: "client1",
      },
      {
        img: "/imgs/clients/c2.png",
        img_alt: "client2",
      },
      {
        img: "/imgs/clients/c3.png",
        img_alt: "client3",
      },
      {
        img: "/imgs/clients/c4.png",
        img_alt: "client4",
      },
      {
        img: "/imgs/clients/c5.png",
        img_alt: "client5",
      },
    ],
  };
  /* Featured */
  const FeaturedData = {
    id: "featured-in",
    header: "Featured In",
    slides: [
      {
        img: "/imgs/home/ndtv_logo.png",
        img_alt: "NDTV",
      },
      {
        img: "/imgs/home/indiatoday_logo.png",
        img_alt: "Indiatoday",
      },
      {
        img: "/imgs/home/yours_story.png",
        img_alt: "YoursStory",
      },
      {
        img: "/imgs/home/et_logo.png",
        img_alt: "The Economic Times",
      },
    ],
  };

  /* Pricing Details */
  const PricingDetails = [
    {
      title: ``,
      body: [
        {
          col: 'col-md-3 col-sm-6',
          header: `Private Limited Company Registration`,
          ofrprice: `9999`,
          price: `14999`,
          color: '',
          card_link: true,
          card_links: '/business-reg/private-limited-company',
          content: [
            {
              point: `Zero Balance Current Account`,
              icon: false,
            },
            {
              point: `Company Incorporation Certificate`,
              icon: false,
            },
            {
              point: `DIN and DSC for two Directors`,
              icon: false,
            },
            {
              point: `Name Approval Certificate`,
              icon: false,
            },
            {
              point: `Drafting of MoA & AoA`,
              icon: false,
            },
            {
              point: `Company PAN and TAN`,
              icon: false,
            },
          ],
        },
        {
          col: 'col-md-3 col-sm-6',
          badge: false,
          header: `Limited Liability Partnership`,
          ofrprice: `7999`,
          price: `12999`,
          color: 'green',
          card_link: true,
          card_links: '/business-reg/limited-liability-partnership',
          content: [
            {
              point: `Online Accounting Software valid for one year`,
              icon: false,
            },
            {
              point: `Zero Balance Current Account`,
              icon: false,
            },
            {
              point: `DSC for one director and DIN for up to three directors`,
              icon: false,
            },
            {
              point: `Registration fees and stamp duty`,
              icon: false,
            },
            {
              point: `Drafting of MoA & AoA`,
              icon: false,
            },
            {
              point: `Company Incorporation Certificate & more`,
              icon: false,
            },
          ],
        },
        {
          col: 'col-md-3 col-sm-6',
          header: `One Person Company`,
          ofrprice: `6499`,
          price: `9999`,
          color: 'blue',
          card_link: true,
          card_links: '/business-reg/one-person-company',
          content: [
            {
              point: `Guidance for choosing the company name`,
              icon: false,
            },
            {
              point: `Name Approval Certificate`,
              icon: false,
            },
            {
              point: `Company Incorporation Certificate`,
              icon: false,
            },
            {
              point: `Drafting of MoA & AoA`,
              icon: false,
            },
            {
              point: `Company PAN and TAN`,
              icon: false,
            },
            {
              point: `DIN and DSC for the Director`,
              icon: false,
            },
          ],
        },
        {
          col: 'col-md-3 col-sm-6',
          header: `Partnership Firm`,
          ofrprice: `12999`,
          price: `15999`,
          color: 'red',
          card_link: true,
          card_links: '/business-reg/partnership-firm-registration',
          content: [
            {
              point: `Two rounds of iterations`,
              icon: false,
            },
            {
              point: `Drafting Partnership Deed`,
              icon: false,
            },
            {
              point: `Guidance on setting up a partnership company`,
              icon: false,
            },
          ],
        },
      ],
    },
  ];
  const PricingDetails1 = [
    {
      title: ``,
      body: [
        {
          col: "col-md-3 col-sm-6",
          header: `Shop & Establishment License`,
          ofrprice: `1499`,
          price: `4199`,
          color: "",
          card_link: true,
          card_links: "/registration/shop-establishment-license",
          content: [
            {
              point: `Introducing a new business venture? A Shops & Establishments
              License must be obtained. The whole process will be handled by professionals,
              who will expedite the process and grant you your license.`,
              icon: false,
            },
          ],
        },
        {
          col: "col-md-3 col-sm-6",
          badge: false,
          header: `FSSAI Registration`,
          ofrprice: `2999`,
          price: `4999`,
          color: "green",
          card_link: true,
          card_links: "/registration/food-license",
          content: [
            {
              point: `Every company that works in the food industry needs to register with the
              FSSAI. You receive assistance in deciding on the best license type for your company.
              Our professionals will handle the filing procedure and provide guidance on how to manage
              your company in accordance with FSSAI regulations to prevent fines.`,
              icon: false,
            },
          ],
        },
        {
          col: "col-md-3 col-sm-6",
          header: `ISO Registration`,
          ofrprice: `2499`,
          price: `7199`,
          color: "blue",
          card_link: true,
          card_links: "/registration/iso-registration",
          content: [
            {
              point: `Every firm benefits from ISO certification, which provides a
               seal of excellence and high standards. With our help, you can choose the
                best sort of ISO certification for your company, and we'll also help you
                choose a reliable vendor and guide you through the entire process.`,
              icon: false,
            },
          ],
        },
        {
          col: "col-md-3 col-sm-6",
          header: `Import and Export Code`,
          ofrprice: `1499`,
          price: `8499`,
          color: "red",
          card_link: true,
          card_links: "/business-reg/partnership-firm-registration",
          content: [
            {
              point: `Are you interested in starting an import/export company?
               An import and export code is required for it. With TODAYFILINGS,
               get your IEC as soon as possible. Our support staff will walk you through
                the procedure and get your IEC to you immediately.`,
              icon: false,
            },
          ],
        },
      ],
    },
  ];

  const PricingDetails2 = [
    {
      title: ``,
      body: [
        {
          col: "col-md-3 col-sm-6",
          header: `Trademark Registration`,
          ofrprice: `6499`,
          price: `4199`,
          color: "",
          card_link: true,
          card_links: "/ipr/trademark-registration",
          content: [
            {
              point: `Customers recognize you by your brand name. Create a trademark
              registration with TODAYFILINGS to safeguard it. Our specialists assist
              you in selecting the appropriate trademark classes and register your trademark
               on your behalf. The TM symbol can be used right away!`,
              icon: false,
            },
          ],
        },
        {
          col: "col-md-3 col-sm-6",
          badge: false,
          header: `Trademark Renewal`,
          ofrprice: `4999`,
          price: `4999`,
          color: "green",
          card_link: true,
          card_links: "/ipr/trademark-renewal",
          content: [
            {
              point: `After 12 months from the date of expiration, if the trademark registration
              holder does not renew the registration, the trademark will be deleted from the Trademarks
              Journal or the Register of Trademarks.`,
              icon: false,
            },
          ],
        },
        {
          col: "col-md-3 col-sm-6",
          header: `Copyright Registration`,
          ofrprice: `14999`,
          price: `7199`,
          color: "blue",
          card_link: true,
          card_links: "/ipr/copyright-registration",
          content: [
            {
              point: `Copyright is a legal prohibition against copying someone else's work.
               The exclusive right to the owner's work or creation is provided by copyright`,
              icon: false,
            },
          ],
        },
        {
          col: "col-md-3 col-sm-6",
          header: `Patent Registration`,
          ofrprice: `11999`,
          price: `8499`,
          color: "red",
          card_link: true,
          card_links: "/ipr/patent-registration",
          content: [
            {
              point: `The patent attorney registration is actually good for around 20 years.
               The patent can be registered for a wide range of procedures, including those involving art,
                a process or method for manufacturing, a machine, a specific device, a technical application
                or app, computer software, pharmaceuticals or chemicals, and more.`,
              icon: false,
            },
          ],
        },
      ],
    },
  ];
  const PricingDetails3 = [
    {
      title: ``,
      body: [
        {
          col: "col-md-3 col-sm-6",
          header: `GST Registration`,
          ofrprice: `499`,
          price: `1999`,
          color: "",
          card_link: true,
          card_links: "/gst/gst-registration",
          content: [
            {
              point: `Apply for GST Registration Online in India with TODAYFILINGS.
              According to the GST legislative process, every business activity or
              organization with an annual turnover over Rs 40 lakhs must go through the
               GST process/procedure in India as a separate taxable provision.`,
              icon: false,
            },
          ],
        },
        {
          col: "col-md-3 col-sm-6",
          badge: false,
          header: `Professional Tax Registration`,
          ofrprice: `999`,
          price: `4999`,
          color: "green",
          card_link: true,
          card_links: "/registration/professional-tax-registration",
          content: [
            {
              point: ` Online Tax Registration for Professionals in India For all
               employees working for a corporation, registering online is required.
               Every business or corporation must do this within a month of being registered
               or starting operations with the aid of Indian tax consultants.`,
              icon: false,
            },
          ],
        },
        {
          col: "col-md-3 col-sm-6",
          header: `Income Tax Returns`,
          ofrprice: `799`,
          price: `7199`,
          color: "blue",
          card_link: true,
          card_links: "/taxation/income-tax-return",
          content: [
            {
              point: `The process of submitting an income tax return, also known as an
               ITR, allows the taxpayer to claim tax deductions, calculate their net tax
               obligation, and keep track of their total taxable income.`,
              icon: false,
            },
          ],
        },
        {
          col: "col-md-3 col-sm-6",
          header: `TDS Return`,
          ofrprice: `999`,
          price: `8499`,
          color: "red",
          card_link: true,
          card_links: "/taxation/tds-return-filing",
          content: [
            {
              point: `TDS Return filing is made simple with TODAYFILINGS because we have the top professionals and only require online verification.`,
              icon: false,
            },
          ],
        },
      ],
    },
  ];
  const PricingDetails4 = [
    {
      title: ``,
      body: [
        {
          col: "col-md-3 col-sm-6",
          header: `Annual Compliance for Pvt.Ltd. Company`,
          ofrprice: `6999`,
          price: `4999`,
          color: "",
          card_link: true,
          card_links:
            "/secretarial-compliances/annual-compliance-of-a-private-limited-company",
          content: [
            {
              point: `One of the highly recommended small enterprises in India, particularly for
              startups, is a Private Limited Company, which is run privately. The Companies Act of
              2013 governs the registration of private limited companies in India.`,
              icon: false,
            },
          ],
        },
        {
          col: "col-md-3 col-sm-6",
          badge: false,
          header: `Secretarial Audit`,
          ofrprice: `2499`,
          price: `4999`,
          color: "green",
          card_link: true,
          card_links: "/accounting/secretarial-audit",
          content: [
            {
              point: `Auditing entails thoroughly inspecting and verifying the company's overall
              operations and functions, data, records, efficiency, and so on. Secretarial auditing
               is the examination of annual conformity with all relevant rules and regulations.`,
              icon: false,
            },
          ],
        },
        {
          col: "col-md-3 col-sm-6",
          header: `Due Diligence`,
          ofrprice: `3999`,
          price: `7199`,
          color: "blue",
          card_link: true,
          card_links: "/accounting/due-diligence",
          content: [
            {
              point: `Due diligence is a procedure of verification that also entails an investigation
               or audit of a potential deal or investment opportunity in order to verify all pertinent
               facts and financial information and to examine everything that was raised throughout any
                investment process or M&A deal. `,
              icon: false,
            },
          ],
        },
        {
          col: "col-md-3 col-sm-6",
          header: `Accounting`,
          ofrprice: `3999`,
          price: `8499`,
          color: "red",
          card_link: true,
          card_links: "/accounting/accounting",
          content: [
            {
              point: `Accounting services in India are among the most essential elements of a
               company because finance is what not only ensures the company's survival but also
               supports its development and progress. `,
              icon: false,
            },
          ],
        },
      ],
    },
  ];
  const PvtLtdTabsData = {
    id: 'pricing',
    class: 'pricing',
    heading: 'Overview of our services',
    paragraph: ``,
    body: [
      {
        tab_title: 'Business Registration',
        tab_content: PricingDetails,
      },
      {
        tab_title: 'Getting License',
        tab_content: PricingDetails1,
      },
      {
        tab_title: 'Intellectual Property',
        tab_content: PricingDetails2,
      },
      {
        tab_title: 'Tax Registration',
        tab_content: PricingDetails3,
      },
      {
        tab_title: 'Mandatory Compliances',
        tab_content: PricingDetails4,
      },
    ],
  };
  return (
    <>
      <Seo
        title='TODAYFILINGS'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          <HomeSlider sliders={KbgSlider} />
          <Card data={indexCardData} />

          <HomeAbout />
          {/*
          <Service item={ServicesData} />
          <CardSection /> */}

          <HomeGuidance />

          {/* <SecuritySection />

<Tabs /> */}
          <Document docSecData={PvtLtdDocData} />
          <PricingTabs HTabsData={PvtLtdTabsData} />

          <Clients data={ClientsData} />
          <Testimonial />
          <CounterUp />

          <Clients data={FeaturedData} />
          <BlogCard />
        </main>
      </Layout>
    </>
  );
}
