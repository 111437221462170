import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade } from 'swiper'

export default function Clients({ data }) {
  return (
    <section id={data.id} className="clients mb-5">
      {/* <div className='container aos-init aos-animate' data-aos='fade-up'> */}
      <div className="container ">
        <header className="section-header mt-5 ">
          <h2 className="mt-5">{data.header}</h2>
          <div className="bar"></div>
        </header>
        <div className="clients-slider swiper">
          <Swiper
            modules={[Autoplay, EffectFade]}
            effect
            speed={100}
            slidesPerView={4}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 40,
              },

              1200: {
                slidesPerView: 4,
              },
            }}
          >
            <div className="swiper-wrapper align-items-center mt-5">
              {data.slides.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="swiper-slide">
                    <img
                      src={slide.img}
                      className="img-fluid"
                      alt={slide.img_alt}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </div>
    </section>
  );
}
