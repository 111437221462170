import { Link } from 'gatsby';
import React from 'react';
import CountUp from "react-countup";

export default function CounterUp() {
    return (
      <section
        id="rs-about"
        className="rs-about rs-counters mt-3 aos-init aos-animate"
      >
        <div className="container counter">
          {/* <!-- About Section Start --> */}
          <div id="rs-about" className="rs-about style2  mt-3">
            <div className="container ">
              <div className="row rs-counter counter-area ">
                <header className="section-header">
                  <h2>We work diligently to fulfill user expectations</h2>
                  <div className="bar"></div>
                </header>
                <div className="col-md-3 mt-3 ">
                  <div className="counter-item one">
                    <h2 className="number rs-count plus">
                      <CountUp end={250} />
                    </h2>
                    <h4 className="title mb-0">Happy Clients</h4>
                  </div>
                </div>
                <div className="col-md-3 mt-3">
                  <div className="counter-item two">
                    <h2 className="number rs-count kplus">
                      <CountUp end={70} />
                    </h2>
                    <h4 className="title mb-0">ITR Filing</h4>
                  </div>
                </div>
                <div className="col-md-3 mt-3">
                  <div className="counter-item two">
                    <h2 className="number rs-count kplus">
                      <CountUp end={75} />
                    </h2>
                    <h4 className="title mb-0">GST Filing</h4>
                  </div>
                </div>

                <div className="col-md-3 mt-3">
                  <div className="counter-item three">
                    <h2 className="number rs-count percent">
                      <CountUp end={100} />
                    </h2>
                    <h4 className="title mb-0">Fast and Secure</h4>
                  </div>
                </div>
              </div>
              <div
                className="contact-cta-box"
                data-aos="fade-up"
                data-aos-offset="300"
              >
                <h3>Have any question about us?</h3>
                <p>Don't hesitate to contact us</p>
                <Link className="btn btn-primary" to="/contact">
                  Contact Us <i className="bi bi-indent m-right"></i>
                </Link>
              </div>
              <div className="map-bg">
                <img src="/imgs/animation/map.png" alt="map" />
              </div>
            </div>
          </div>
        </div>
        {/*  <!-- About Section End --> */}
      </section>
    );
}
