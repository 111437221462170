import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import PricingComp from '../PricingComp';

export default function PricingTabs({ HTabsData }) {
  return (
    <section id={HTabsData.id} className="pricing">
      <div className="container mt-5">
        <div className="section-header">
          <h2>{HTabsData.heading}</h2>
          <div className="bar"></div>
        </div>
        <p
        // className='aos-init aos-animate'
        // data-aos='fade-up'
        // data-aos-offset='100'
        >
          {HTabsData.paragraph}
        </p>
      </div>
      <div
        className="container aos-init aos-animate htabs mt-5"
        // data-aos="fade-up"
        // data-aos-offset="300"
      >
        <Tab.Container defaultActiveKey="tab0">
          <div className="row">
            <Nav variant="pills" className="">
              {HTabsData.body.map((each_tab, index) => (
                <Nav.Item className="col-12 col-md-3 col-lg-3" key={index}>
                  <Nav.Link eventKey={`tab${index}`}>
                    <h5>{each_tab.tab_title}</h5>
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </div>
          <div className="tab-content">
            <Tab.Content>
              {HTabsData.body.map((each_tab, index) => (
                <Tab.Pane eventKey={`tab${index}`} key={index}>
                  <div className="tab-pane active show" id="tab-1">
                    <div className="col-lg-12 order-2 order-lg-1 mt-5">
                      {/* {alert('sadsd')} */}
                      <PricingComp priceTable={each_tab.tab_content} />
                    </div>
                  </div>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </section>
  );
}
